var $ = require( "jquery" )

require("slick-carousel")

import "slick-carousel/slick/slick.scss"

window.addEventListener("DOMContentLoaded", function () {

  document.addEventListener("turbolinks:load", function() {
    // /* **** Hero Slider **** */
    $(".hero-slider").slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1000,
      cssEase: "linear",
      prevArrow: "<button type='button' class='slick-prev slick-arrow'><i class='fal fa-chevron-left'></i></button>",
      nextArrow: "<button type='button' class='slick-next slick-arrow'><i class='fal fa-chevron-right'></i></button>",
      responsive: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
              arrows: false,
          },
        },
      ],
      lazyLoad: 'ondemand', // Enable lazy loading
      lazyLoadBuffer: 1000, // Load images as soon as they appear in the viewport
    });
    // /* **** End Hero Slider **** */

    // /* **** Hero Slider **** */
    $(".fresh-slider").slick({
        infinite: true,
        slidesToShow: 3,
        prevArrow: "<button type='button' class='slick-prev slick-arrow'><i class='fal fa-chevron-left'></i></button>",
        nextArrow: "<button type='button' class='slick-next slick-arrow'><i class='fal fa-chevron-right'></i></button>",
        responsive: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    centerMode: true,
                    arrows: false,
                    centerPadding: "210px 0 0",
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 447,
                settings: {
                    centerMode: true,
                    arrows: false,
                    centerPadding: "150px 0 0",
                    slidesToShow: 1,
                },
            },
        ],
    });
    // /* **** End Hero Slider **** */

    // /* **** Hero Slider **** */
    $(".products-slider").slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: "<button type='button' class='slick-prev slick-arrow'><i class='far fa-chevron-left'></i></button>",
        nextArrow: "<button type='button' class='slick-next slick-arrow'><i class='far fa-chevron-right'></i></button>",
        responsive: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    centerMode: true,
                    arrows: false,
                    centerPadding: "210px 0 0",
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 447,
                settings: {
                    centerMode: true,
                    arrows: false,
                    centerPadding: "150px 0 0",
                    slidesToShow: 1,
                },
            },
        ],
    });
    // /* **** End Hero Slider **** */

    // /* **** Product Slider **** */


    // $(".slider-nav").slick("slickGoTo", 1);
    // /* **** End Product Slider **** */

    // /* ***** Quantity Inputs **** */
    $(".quantity").on("click", ".plus", function (e) {
        let $input = $(this).prev("input.qty");
        let val = parseInt($input.val());
        $input.val(val + 1).change();
    });

    $(".quantity").on("click", ".minus", function (e) {
        let $input = $(this).next("input.qty");
        var val = parseInt($input.val());
        if (val > 1) {
            $input.val(val - 1).change();
        }
    });
    // /* ***** End Quantity Inputs **** */

    // /* **** Fix Header **** */
    $(window).scroll(function () {
        if ($(this).scrollTop() > 218) {
            $("header").addClass("sticky");
        } else {
            $("header").removeClass("sticky");
        }
    });
    // /* **** End Fix Header **** */

    // /* **** Fix Header **** */
    $(window).scroll(function () {
        if ($(this).scrollTop() > 164) {
            $(".top-head").addClass("top");
        } else {
            $(".top-head").removeClass("top");
        }
    });


    document.querySelectorAll(".accordion-header").forEach((header) => {
        header.addEventListener("click", () => {
            const content = header.nextElementSibling;
            content.classList.toggle("show");
        });
    });

    // Product Gallery
    function initializeProductGallery() {
        const gallery = document.getElementById('product-gallery');
        if (!gallery) return;

        const variantRadios = document.querySelectorAll('.product-variants-variant-values-radio');
        const productImages = gallery.querySelectorAll('.product-image');

        function updateGalleryImage(variantId) {
            let imageFound = false;
            productImages.forEach(image => {
                if (image.dataset.variantId === variantId) {
                    image.classList.remove('hidden');
                    imageFound = true;
                } else {
                    image.classList.add('hidden');
                }
            });

            // If no specific image found for the variant, show the first image (which might be for all variants)
            if (!imageFound) {
                productImages[0].classList.remove('hidden');
            }
        }

        variantRadios.forEach(radio => {
            radio.addEventListener('change', (event) => {
                updateGalleryImage(event.target.dataset.variantId);
            });
        });

        // Set initial image
        const initialSelectedRadio = document.querySelector('.product-variants-variant-values-radio:checked');
        if (initialSelectedRadio) {
            updateGalleryImage(initialSelectedRadio.dataset.variantId);
        } else {
            // If no variant is selected, show the first image
            productImages[0].classList.remove('hidden');
        }
    }

    // Initialize the product gallery
    initializeProductGallery();

    // /* **** Add Remove Class **** * /

    // /* **** End Add Remove Class **** */
    $(".accordion-header").click(function () {
        $(this).toggleClass("show");
    });

    /* **** Tabs **** */
    // This is not in use and raising exception
    // let tabsContainer = document.querySelector("#tabs");
    // let tabTogglers = tabsContainer.querySelectorAll("#tabs a");
    // console.log(tabTogglers);
    // tabTogglers.forEach(function (toggler) {
    //     toggler.addEventListener("click", function (e) {
    //         e.preventDefault();
    //         let tabName = this.getAttribute("href");
    //         let tabContents = document.querySelector("#tab-contents");
    //         for (let i = 0; i < tabContents.children.length; i++) {
    //             tabTogglers[i].parentElement.classList.remove("border-darker-foliage", "text-darker-foliage", "border-transparent");
    //             tabContents.children[i].classList.remove("hidden");
    //             if ("#" + tabContents.children[i].id === tabName) {
    //                 continue;
    //             }
    //             tabContents.children[i].classList.add("hidden");
    //         }
    //         e.target.parentElement.classList.add("border-darker-foliage", "text-darker-foliage");
    //     });
    // });
    /* **** End Tabs **** */
  });
});
